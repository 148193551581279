import React from 'react';
import './HotLine.scss';
import {PhoneIcon} from '../../icons';
import Phone from '../Phone';
import {useTranslation} from 'react-i18next';

const HotLine = (props) => {
  const {t} = useTranslation();

  return (
      <div className="hot-line">
        <div className="hot-line__icon">
          <PhoneIcon/>
        </div>
        <div className="hot-line__main">
          <div className="hot-line__text">
            { t('Горячая линия') }
          </div>
          <div className="hot-line__phone">
            <Phone phone="74952215259"
                   formattedPhone="+7 (495) 221-52-59"/>
          </div>
        </div>
      </div>
  );
};

export default HotLine;
