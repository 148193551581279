import React from 'react';
import './Contacts.scss';
import {useTranslation} from 'react-i18next';

const Contacts = ({className = '', ...props}) => {
  const {t} = useTranslation();

  return (
      <div className={ `contacts ${ className }` }>
        <div className="contacts__city">
          { t('Московская обл., г. Балашиха, п-кт Ленина 32А') }
        </div>
        <div className="contacts__phone">
          <a href="tel:+74952215259">
            +7 (495) 221-52-59
          </a>
        </div>
      </div>
  );
};

export default Contacts;
