import {NoCommissionIcon, RentabelnoIcon, ShieldIcon} from '../images';
import React from 'react';
import {FeatureCoinIcon, FeatureComfortIcon, FeatureLocationIcon, FeatureParkingIcon, FeatureSmileIcon} from '../icons';


export const TIMEZONE = process.env.REACT_APP_TIMEZONE;
export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';

export const OFFICE = 'Office';
export const PARKING = 'Parking';
export const APARTMENT = 'Apartment';

export const LOFT_SUBTYPE = 'Loft';
export const HOUSE_SUBTYPE = 'House';
export const FLAT_SUBTYPE = 'Flat';
export const APARTMENT_SUBTYPE = 'Apartments_subtype';

export const LONG_TERM_TYPE = 1;
export const SHORT_TERM_TYPE = 2;

export const REQUEST_RENT_TYPE = 'rent';
export const REQUEST_INFO_TYPE = 'info';
export const REQUEST_SELL_TYPE = 'on_sell';
export const REQUEST_AVAILABILITY_TYPE = 'time_to_availability';

export const XSRF_TOKEN = 'XSRF-TOKEN';
export const SITE_AUTH_CHECK = `${window.location.hostname}/auth`;
export const EMAIL_REDIRECT_SUPPORT = 'email_redirect';
export const SUPPORT_REDIRECT_VARIABLE = 'support_redirect';

export const ERROR_500_MESSAGE = 'Запрос не может быть обработан. Попробуйте позже.';
export const ERROR_NETWORK = 'Отсутствует подключение к интернету.';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const INFO = 'info';
export const WARNING = 'warning';
export const FAILURE = 'failure';

export const ADVANTAGE_POINTS = [
  {title: 'Мы не берем никаких комиссий', icon: () => <NoCommissionIcon/>},
  {title: 'Все объекты являются нашей собственностью', icon: () => <RentabelnoIcon/>},
  {title: 'Вы гарантированно получаете выбранный объект за указанную стоимость', icon: () => <ShieldIcon/>}
];

export const TABS_OPTIONS = [
  {title: 'Жилье', value: APARTMENT},
  {title: 'Паркинги', value: PARKING},
  {title: 'Офисы', value: OFFICE}
];

export const APARTMENTS_FEATURES_SMILE_ITEM = {title: 'Приятные предложения', icon: () => <FeatureSmileIcon/>};
export const APARTMENTS_FEATURES_COMFORT_ITEM = {title: 'Уютный интерьер', icon: () => <FeatureComfortIcon/>};
export const APARTMENTS_FEATURES_COIN_ITEM = {title: 'Без комиссий', icon: () => <FeatureCoinIcon/>};
export const APARTMENTS_FEATURES_COMPANY_ITEM = {title: 'От собственника', icon: () => <FeatureParkingIcon/>};

export const PARKING_FEATURES_PIN_ITEM = {title: 'Удобное расположение', icon: () => <FeatureLocationIcon/>};

export const FEATURES_APARTMENTS = [
  APARTMENTS_FEATURES_SMILE_ITEM,
  APARTMENTS_FEATURES_COMFORT_ITEM,
  APARTMENTS_FEATURES_COIN_ITEM,
  APARTMENTS_FEATURES_COMPANY_ITEM
];

export const FEATURES_PARKING_OFFICE = [
  APARTMENTS_FEATURES_SMILE_ITEM,
  PARKING_FEATURES_PIN_ITEM,
  APARTMENTS_FEATURES_COIN_ITEM,
  APARTMENTS_FEATURES_COMPANY_ITEM
];

export const NOT_FOUND_DATA = 'К сожалению, по таким параметрам ничего не найдено';
export const ERROR_LOADING_DATA = 'К сожалению Ваш запрос не был обработан, попробуйте позже.';
export const FORBIDDEN = 'FORBIDDEN';


export const FORBIDDEN_MESSAGE = 'Такой клиент уже авторизован.';
export const SUCCESS_AVAILABLE_REQUEST_MESSAGE = 'Мы обязательно свяжемся c Вами, как только объект освободится.';
export const SUCCESS_RENT_REQUEST_MESSAGE = 'Заявка на аренду успешно принята, скоро менеджер свяжеться с Вами для уточнения деталей.';
export const SUCCESS_SALE_REQUEST_MESSAGE = 'Ваша заявка на продажу принята, скоро менеджер свяжеться с Вами.';
export const EMAIL_VERIFY_MESSAGE = 'Письмо с подтверждением отправлено на указанный Вами при регистрации адрес электронной почты.';
export const SUCCESS_METERS_DATA = 'Показатели успешно переданы';
export const DONT_MESSAGE_DATA = 'Показаний пока нет';
export const PROFILE_SUCCESS = 'Профиль упешно обновлен!';

export const BLOB_TYPE = 'blob';

export const REALTY_RENT = 'Rent';
export const REALTY_SALE = 'Sale';

export const REALTY_AVAILABLE = 1;
export const REALTY_RESERVED = 2;
export const REALTY_OCCUPIED = 3;
export const REALTY_CLEANING_NEEDED = 4;
export const REALTY_PARTIAL_RENT = 5;

export const TIME_INTERVAL_REQUEST_CHAT = 10000;

export const VISIBLE = 'visible';
export const HIDDEN = 'hidden';
export const VISIBILITY_CHANGE = 'visibilitychange';

export const RECAPTCHA_ACTION_RENT = 'rent';
export const RECAPTCHA_ACTION_SALE = 'on_sell';
export const RECAPTCHA_ACTION_INFO = 'info';
export const RECAPTCHA_ACTION_AVAILABILITY = 'time_to_availability';


export const UA = 'ua';
export const UK = 'uk';
export const RU = 'ru';
export const MD = 'md';

export const I18N_LANGUAGE = 'i18nextLng'


//bookings statuses

export const BOOKING_ACTIVE = 1;
export const BOOKING_CLOSE = 2;
export const BOOKING_DEBTOR = 4;
export const BOOKING_CREDITOR = 5;
